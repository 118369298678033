export enum TokenTypes {
  Bearer = 'bearer'
}

export enum UserType {
  admin = 'admin',
  manual = 'manual',
  azure = 'azure_ad',
  auto = 'auto'
}

export interface UserInfoFromAccessToken {
  portal_id: string
  scope: string
  user_type: UserType
}

export interface BCCAccount {
  id: string
  name: string
}

export interface UserInfo {
  accounts: BCCAccount[]
  authToken: string
  userId: number
  portalId: string
  defaultPortalId: string
  timezone: string
  name: string
  username: string
  hasAdmin: boolean
  isMsp: boolean
  guestUser: boolean
}

export interface UserFeatures {
  optOutEnabled: boolean
  optInEnabled: boolean
  userEndpoint: 'TDF' | 'React'
  isEmailLogEnabled: boolean
}

export interface PamProductDataResponse {
  data: {
    subscriptionGroups: any[]
    pendingActivations: any[]
    error: {
      code: string
    }
  }
}

export interface UserStates {
  isRolledOutUserRole: boolean
  isRolledOutOktaUser: boolean
}

export interface User {
  companyId: string
  companyName: string
  accountId: string
  bccAccountId: string
  domainId: number
  sessionId: string
  type: UserType
  userId: string
  portalId: number
  scope: string
  userInfo?: UserInfo
  isCplAccount: boolean
  pdDomainId: string | null
  pdDomainName: string | null
  isMspRoleValidated: boolean
  serial: string
  serialNumber: string
  isAzureAdAccount: boolean
  roleType: number
  isImpersonated: boolean
  isUsedOnetimePasscode: boolean
  userStates: UserStates
  timezone?: string
  features?: UserFeatures
  productData?: PamProductDataResponse
}

export interface LoginResponse {
  accessToken: string
  accessTokenExpires: number
  sessionId: string
  tokenType: TokenTypes
}

export interface DomainInfo {
  region: string
}
